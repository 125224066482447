import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"

export default ({ data }) =>  (
  <Layout>
    <SEO title="Home" keywords={[`website`, `application`, `react`, `redux`, `vue`, `vuex`, `frontend`, `developer`, `javascript`, `php`, `html`, `css`]} />
    <div className="project">
      <div className="inline-block mb-4">
        <img src="../profile.jpg" alt="Me" className="block" />
      </div>
      <h1 className="h2">About Me</h1>
      <p>I work primarily as a <strong>Javascript Developer</strong>. My career focus has largely been as a <strong>Frontend Developer</strong>, building websites and applications with <strong>Javascript</strong>, <strong>CSS</strong> and <strong>HTML</strong>. I’ve also worked with backend technologies, primarily <strong>PHP, Wordpress and Laravel</strong>.</p>
      <p>My favourite part of being a developer is <strong>working with others</strong>. I find it <strong>most rewarding</strong> when <strong>pair programming</strong>, where both parties can <strong>knowledge share</strong> and improve on a project. It doesn’t matter if you’re 6 months in, or a 10 year veteran, everyone can contribute, and <strong>not one person has all of the answers</strong>.</p>
      <p><strong>Humbleness, respect, creativity and curiosity are all traits that a positive work environment should have</strong>.</p>
      <p>I am a <strong>“forever student”</strong>. It is impossible to know everything, so I will continue to <strong>study and practice</strong> and explore this landscape, and hopefully <strong>produce some cool work along the way</strong>.</p>
      <h2 className="mt-12">How did I get here?</h2>
      <p>It all started in the late 90’s. The company I was working for at that time had an Art Department. I was working in the Maintenance Department. I’d always had an interest in <strong>design</strong>, but I didn’t have any computer experience to compliment it. Fortunately, I was able to make an arrangement with the Marketing Team. If I could learn QuarkXpress (wow does that date me), they’d throw me a couple bones.</p>
      <p>I picked up a book and spent every lunch hour <strong>studying</strong> and <strong>practicing</strong> on the company’s Macs, until one day, they finally gave me my first ad. It was at that moment that I realized, I could do anything if I set my mind to it. A few ads later, the team asked if I could put a small web page together for a fundraiser. I picked up another book on <strong>HTML</strong>. I started piecing small tables together (yeah, tables, that’s a long time ago) until I had a few pages connecting to each other. Amazing.</p>
      <p>Once I left that job, I really needed a way to showcase some of the design work I’d done. So, I dove deeper into HTML. Eventually I had an online portfolio and was able to land my first official design job. I was hooked. Being able to produce web pages, so quickly, without need for printed proofs and long delays in production, blew my mind. It was immediate, and rewarding.</p>
      <p>Following that, I picked up books on Illustrator, Photoshop, Javascript, PHP, CSS, Design and more. My passion for <strong>technical books</strong> and motivation to learn and understand the technical landscape of <strong>websites and applications</strong> took over.</p> 
      
      <Link to="/#work" className="btn btn--lg mt-8">My Latest Work</Link>
    </div>
  </Layout>
)
